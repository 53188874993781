import styled, { css } from 'styled-components';

const ContentWrapper = styled.div`
  background-color: #fff;
  padding: 10px 30px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5aad2;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a28bd2;
  }

  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.fd &&
    css`
      flex-direction: column;
    `}
    ${props =>
    props.position &&
    css`
      position: ${props.position};
      top: 0;
    `}
  ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
    ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
    ${props =>
    props.columns &&
    css`
      display: grid;
      grid-template-columns: ${props.columns};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height};
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.overflow_y &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: ${props.overflow_y};
    `}
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow};
    `}
    ${props =>
    props.grid_columns &&
    css`
      display: grid;
      grid-template-columns: ${props.grid_columns};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
    ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
    ${props =>
    props.text_align &&
    css`
      background-color: ${props.text_align};
    `}
    ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
      ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.border_right &&
    css`
      border-right: 1px solid ${props.border_right};
    `}
    ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${props.borderBottom};
    `}
    ${props =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius}px;
    `}
    ${props =>
    props.type === 'grid' &&
    css`
      display: grid;
      grid-template-columns: 1fr 4fr;
    `}
    ${props =>
    props.gap &&
    css`
      grid-gap: 32px;
    `}
    ${props =>
    props.overflowY &&
    css`
      overflow-y: ${props.overflowY};
    `}
    ${props =>
    props.type === 'calendar' &&
    css`
      & .ant-picker-calendar-date-content ul {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      & .ant-picker-calendar,
      & .ant-picker-panel {
        background: transparent;
      }
      & .ant-picker-content {
        border-collapse: collapse;
        & thead {
          height: 45px;
        }
        & thead tr th {
          border-right: 3px solid transparent !important;
        }
        & tbody {
          background: white;
          & tr:last-child {
            display: flex;
            width: 0px;
            height: 0px;
            overflow: hidden;
          }
          & .ant-picker-calendar-date-content {
            overflow-y: clip !important;
          }
          & .ant-picker-cell-inner {
            border-right: 1px solid #f0f1f4;
            margin: 0;
          }
          & .ant-picker-cell .ant-picker-cell-inner .ant-picker-calendar-date-value {
            color: #c4c4c4 !important;
          }
          & .ant-picker-cell-in-view .ant-picker-cell-inner .ant-picker-calendar-date-value {
            color: inherit !important;
          }
          & .events,
          & {
            text-align: initial;
            list-style-type: none !important;
            margin-top: 5px;
            overflow: hidden;
          }
          & .ant-picker-calendar-date-value {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
          }
        }
        & thead tr th {
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
      }
      & .ant-picker-calendar-header {
        justify-content: flex-start;
        margin-bottom: 32px;
        & .ant-radio-group {
          display: none;
        }
        & .ant-select {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          & .ant-select-selector {
            border: none !important;
            background: transparent !important;
            padding: 0 !important;
            & .ant-select-selection-item {
              width: 103px;
              display: flex;
              align-items: center;
              border: 1px solid rgb(217, 217, 217);
              padding: 2px 18px 2px 9px;
              border-radius: 4px;
            }
          }
        }
      }
    `}
    ${props =>
    props.spin_full_height &&
    css`
      & .ant-spin-nested-loading,
      & .ant-spin-container {
        height: 100%;
      }
    `}
`;

export default ContentWrapper;
