import { createCustomApi } from 'configs/rtk-query';

export const usersApi = createCustomApi({
  reducerPath: 'users',
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => '/users',
    }),
    getLoggedUser: builder.query({
      query: id => `/users/${id}`,
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery, useLazyGetLoggedUserQuery } = usersApi;
