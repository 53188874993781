import { IconWrapper } from '../../components/atoms';

const HomeSvg = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.9441 10.2734C19.8906 10.4039 19.7995 10.5155 19.6826 10.5943C19.5656 10.6731 19.428 10.7155 19.2869 10.7162H17.1438V17.8576C17.1438 18.4258 16.918 18.9707 16.5161 19.3725C16.1142 19.7743 15.569 20 15.0006 20H12.1431C11.9537 20 11.772 19.9248 11.638 19.7908C11.504 19.6569 11.4288 19.4753 11.4288 19.2859V15.001C11.4288 14.8116 11.3535 14.63 11.2195 14.4961C11.0855 14.3621 10.9038 14.2869 10.7144 14.2869H9.28562C9.09616 14.2869 8.91445 14.3621 8.78048 14.4961C8.64651 14.63 8.57124 14.8116 8.57124 15.001V19.2859C8.57124 19.4753 8.49598 19.6569 8.36201 19.7908C8.22803 19.9248 8.04633 20 7.85687 20H4.99935C4.43096 20 3.88584 19.7743 3.48393 19.3725C3.08201 18.9707 2.85622 18.4258 2.85622 17.8576V10.7162H0.713083C0.572046 10.7155 0.434374 10.6731 0.317416 10.5943C0.200458 10.5155 0.109447 10.4039 0.0558546 10.2734C0.00114793 10.1434 -0.0137988 10.0001 0.0129005 9.86152C0.0395998 9.72298 0.106749 9.59546 0.205874 9.49503L9.49279 0.211241C9.5592 0.144306 9.63821 0.091178 9.72527 0.0549221C9.81232 0.0186663 9.90569 0 10 0C10.0943 0 10.1877 0.0186663 10.2747 0.0549221C10.3618 0.091178 10.4408 0.144306 10.5072 0.211241L19.7941 9.49503C19.8933 9.59546 19.9604 9.72298 19.9871 9.86152C20.0138 10.0001 19.9989 10.1434 19.9441 10.2734Z'
      fill='white'
    />
  </svg>
);
const HomeIcon = props => <IconWrapper component={HomeSvg} {...props} />;

export default HomeIcon;
