import { IconWrapper } from '../../components/atoms';

const SettingSvg = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.09661 7.82838L2.66345 7.62917C2.79267 7.23167 2.95179 6.84833 3.13774 6.48417L2.17009 5.23823C1.77841 4.73411 1.82419 4.0224 2.27182 3.58906L3.58432 2.27661C4.02173 1.82495 4.73348 1.78 5.23866 2.17083L6.48277 3.13844C6.84701 2.9525 7.2303 2.79339 7.62865 2.66417L7.82788 1.10005C7.90273 0.472396 8.43541 0 9.06611 0H10.9331C11.5638 0 12.0964 0.472396 12.1713 1.0974L12.3705 2.66417C12.7689 2.79339 13.1522 2.95167 13.5164 3.13844L14.7616 2.17083C15.2649 1.78 15.9766 1.82495 16.4108 2.27255L17.7233 3.58417C18.175 4.0224 18.2208 4.73411 17.8291 5.23927L16.8614 6.48417C17.0482 6.84833 17.2065 7.23167 17.3357 7.62917L18.8999 7.82838C19.5276 7.90323 20 8.43588 20 9.06656V10.9334C20 11.5641 19.5276 12.0968 18.9026 12.1716L17.3357 12.3708C17.2065 12.7683 17.0474 13.1517 16.8614 13.5158L17.8291 14.7618C18.2208 15.2659 18.175 15.9776 17.7274 16.4109L16.4149 17.7234C15.9766 18.1742 15.2657 18.2192 14.7605 17.8284L13.5156 16.8608C13.1514 17.0475 12.768 17.2066 12.3705 17.335L12.1713 18.8983C12.0964 19.5276 11.5638 20 10.9331 20H9.06611C8.43541 20 7.90273 19.5276 7.82788 18.9026L7.62865 17.3358C7.23114 17.2066 6.84779 17.0475 6.48361 16.8616L5.23762 17.8292C4.73348 18.2208 4.02173 18.1751 3.58838 17.7274L2.27588 16.415C1.82419 15.9768 1.77841 15.2659 2.17009 14.7607L3.13774 13.5158C2.95096 13.1517 2.79184 12.7683 2.66345 12.3708L1.1001 12.1716C0.472626 12.0968 1.90735e-06 11.5641 1.90735e-06 10.9334V9.06656C-0.000831604 8.43588 0.471584 7.90323 1.09661 7.82838ZM9.99958 14.1667C12.2972 14.1667 14.1664 12.2976 14.1664 10C14.1664 7.70245 12.2972 5.83333 9.99958 5.83333C7.70194 5.83333 5.83274 7.70245 5.83274 10C5.83274 12.2976 7.70194 14.1667 9.99958 14.1667Z'
      fill='white'
    />
  </svg>
);
const SettingIcon = props => <IconWrapper component={SettingSvg} {...props} />;

export default SettingIcon;
