import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Header = styled(layout.Header)`
  line-height: initial;
  background: white;
  padding: 0 38px 0 34px;
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.box_shadow &&
    css`
      box-shadow: ${props.box_shadow};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
`;

export default Header;
