export const Key = () => Math.random().toString(36).substring(2, 5);

export const filterOption = (value, option) => {
  const children = Array.isArray(option?.children)
    ? (option?.children || []).join('')
    : option?.children;
  return (children || '').toLowerCase().includes(value.toLowerCase());
};

export const checkAllSelect = (values = [], key, form, setState = () => {}, customAny) => {
  const any = customAny || 'any';
  if (values.includes('all')) {
    if (values.indexOf('all') !== 0) {
      form.setFieldValue(key, ['all'], setState, form);
    }
    if (values.length === 1 && values.indexOf('all') === 0) {
      form.setFieldValue(key, values, setState, form);
    }
    if (values.length > 1 && values.indexOf('all') === 0) {
      form.setFieldValue(
        key,
        values.filter(el => el !== 'all'),
        setState,
        form
      );
    }
  } else if (values.includes(any)) {
    if (values.indexOf(any) !== 0) {
      form.setFieldValue(key, [any], setState, form);
    }
    if (values.length === 1 && values.indexOf(any) === 0) {
      form.setFieldValue(key, values, setState, form);
    }
    if (values.length > 1 && values.indexOf(any) === 0) {
      form.setFieldValue(
        key,
        values.filter(el => el !== any),
        setState,
        form
      );
    }
  } else {
    form.setFieldValue(
      key,
      values.filter(el => el !== 'all' && el !== 'any'),
      setState,
      form
    );
  }
};

export const handleSortChange = (sortDate, SortDataCall, path, setPath) => {
  if (sortDate?.order === 'ascend') {
    setPath
      ? setPath({
          ...path,
          _sort: `${sortDate?.column?.key}:asc`,
        })
      : SortDataCall({
          ...path,
          _sort: `${sortDate?.column?.key}:asc`,
        });
  } else if (sortDate?.order === 'descend') {
    setPath
      ? setPath({
          ...path,
          _sort: `${sortDate?.column?.key}:desc`,
        })
      : SortDataCall({
          ...path,
          _sort: `${sortDate?.column?.key}:desc`,
        });
  } else {
    setPath
      ? setPath({
          ...path,
          _sort: 'id:desc',
        })
      : SortDataCall(path);
  }
};
