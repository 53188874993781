import { useState } from 'react';
import { OpenNotification } from 'components/molecules/openNotification/index';

const useApiCall = (callFunc, countFunc, withoutLoading) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);

  const handleApiCall = (params, callback = () => {}, onError) => {
    handleLoadingChange(true);
    navigator.onLine &&
      callFunc(params)
        .then(res => {
          setData(res?.data || []);
          handleLoadingChange();
          callback(res?.data || []);
        })
        .catch((...props) => {
          onError ? onError(...props) : OpenNotification('Something went wrong');
          handleLoadingChange();
        });
    if (countFunc) {
      countFunc(params)
        .then(res => {
          setCount(res?.data || 0);
        })
        .catch(() => {});
    }
  };

  const handleLoadingChange = (value = false) => {
    if (!withoutLoading) {
      setLoading(value);
    }
  };

  const setDefaultValues = () => {
    setData([]);
    setCount(0);
  };
  return [data, loading, handleApiCall, count, setDefaultValues, setData];
};

export default useApiCall;
