import styled, { css } from 'styled-components';
import { Modal as modal } from 'antd';

const Modal = styled(modal)`
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-header {
      border-radius: 20px 20px 0 0;
    }
  }
  ${props =>
    props.header_border_bottom &&
    css`
      & .ant-modal-header {
        border-bottom: 1px solid ${props.header_border_bottom} !important;
      }
    `}
  ${props =>
    props.sec_type === 'order-Details-title' &&
    css`
      .ant-modal-header {
        & .ant-modal-title {
          font-weight: 400 !important;
        }
      }
    `}
    
    ${props =>
    props.sec_type === 'technician' &&
    css`
      & .ant-modal-header {
        border-bottom: 1px solid #f0f1f4 !important;
      }
    `}
    ${props =>
    props.footer_border &&
    css`
      & .ant-modal-body .footer {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -32px;
          width: calc(100% + 32px + 32px);
          height: 1px;
          background-color: ${props.footer_border} !important;
        }
      }
    `}
  ${props =>
    props.type === 'order-Details' &&
    css`
      & .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 462px;
        & .ant-modal-body {
          padding: 32px;
        }
        & .ant-modal-body .ant-layout-content {
          border-top: none;
        }
        & .ant-modal-footer {
          margin-top: auto;
          border-top: none;
          text-align: start;
          padding: 32px;
          display: flex;
          & button:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #b2b7c2;
            border-radius: 25px;
            width: 134px;
            height: 40px;
            margin-right: 32px;
            & span {
              color: #b2b7c2;
              font-size: 18px;
              line-height: 21px;
              font-weight: 700;
            }
          }
        }
      }
      .ant-modal-header {
        padding: 32px 0 32px 36px;
        & .ant-modal-title {
          font-size: 24px;
          line-height: 28px;
          font-weight: 700;
          color: #171b1e !important;
        }
      }
      & .ant-modal-close-x span svg {
        height: 25px;
        width: 25px;
      }
      & .ant-modal-close {
        top: 22px;
        right: 17px;
      }
    `}
  ${props =>
    props.padding_footer &&
    css`
      & .ant-modal-footer {
        padding: ${props.padding_footer} !important;
      }
    `}
  ${props =>
    props.height &&
    css`
      & .ant-modal-content {
        height: ${props.height} !important;
        & .ant-modal-body {
          padding: 0 !important;
        }
        & .ant-modal-header {
          border-bottom: none;
        }
      }
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.border_bottom &&
    css`
        & .ant-modal-content .ant-modal-header {
            border-bottom: 1px solid ${props.border_bottom} !important;
          }
        }
      `}
`;

export default Modal;
