import styled, { css } from 'styled-components';
import Icon from '@ant-design/icons';

const IconWrapper = styled(Icon)`
  ${props =>
    props.color &&
    css`
      svg path {
        fill: ${props.color};
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.color &&
    css`
      fill: ${props.color} !important;
      color: ${props.color} !important;
      &:hover {
        fill: ${props.color} !important;
        color: ${props.color} !important;
      }
      & path {
        color: ${props.color} !important;
        fill: ${props.color} !important;
      }
      & path:hover {
        color: ${props.color} !important;
        gill: ${props.color} !important;
      }
    `}
    ${props =>
    props.size &&
    css`
      width: fit-content;
      height: fit-content;
      & svg {
        width: ${props.size};
        height: ${props.size};
      }
    `}
    ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
    ${props =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
`;

export default IconWrapper;
