import styled, { css } from 'styled-components';
import { Form } from 'antd';

const FormItem = styled(Form.Item)`
  max-width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 0;
  margin-bottom: 10px;
  & input {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .ant-form-item-label {
    padding: 0 0 3px 0;
    max-height: 30px;
  }
  .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-form-item-label > label {
    font-weight: 400;
    font-size: 14px;
    color: #717579;
    margin-bottom: 8px;

    &:before {
      color: #717579;
    }
  }

  &:focus-within .ant-form-item-label > label {
    color: #886cc0;

    &:before {
      color: #886cc0;
    }
  }
  ${props =>
    (props.label_mb || props.label_mb === 0) &&
    css`
      .ant-form-item-label > label {
        margin-bottom: ${props.label_mb}px;
      }
    `}
  ${props =>
    props.sec_type === 'align' &&
    css`
      & .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px !important;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.margin_right &&
    css`
      margin-right: ${props.margin_right};
    `}
  ${props =>
    props.justify &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.justify};
      }
    `}
  ${props =>
    props.layout === 'vertical' &&
    css`
      div {
        width: 100%;
        text-align: start;
      }
    `}
  
  ${props =>
    props.content_just &&
    css`
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${props.content_just};
      }
    `}
 
  ${props =>
    props.hide === 'requiredText' &&
    css`
      .ant-form-item-explain {
        display: none;
      }
    `}
  ${props =>
    props.error_padding &&
    css`
      .ant-form-item-explain {
        padding: ${props.error_padding};
      }
    `}
  ${props =>
    props.display_item === 'none' &&
    css`
      .ant-form-item-control-input {
        display: ${props.display_item};
      }
    `}
  ${props =>
    props.show_only === 'validate' &&
    css`
      .ant-form-item-control-input,
      .ant-form-item-label {
        display: none;
      }
    `}
    ${props =>
    props.sec_type === 'reject-Notes' &&
    css`
      display: block !important;
      & .ant-form-item-label {
        color: #b2b7c2 !important;
        margin-bottom: 10px;
      }
      & .ant-form-item-control-input {
        width: 454px;
      }
    `}
    ${props =>
    props.type === 'Technician-modal' &&
    css`
      margin-bottom: 0px !important;
      .ant-form-item-label > label:after {
        margin: 0 !important;
        content: '' !important;
      }
      & .ant-form-item-label {
        width: 201px;
      }

      & .ant-form-item-control {
        max-width: fit-content;
        & .ant-input-number {
          width: fit-content;
          border: none;
          border-bottom: 1px solid #eeeeee;
          box-shadow: none !important;
          & input {
            box-shadow: none !important;
          }
        }
      }
      & .ant-form-item-control-input-content {
        text-align: initial;
        & input {
          width: 263px;
        }
        & .ant-input-password {
          width: 263px;
          & input {
            width: 263px !important;
          }
        }
      }
      & .ant-form-item-explain {
        width: 263px !important;
      }
      & .ant-form-item-required {
        align-items: end;
        margin-bottom: 0 !important;
      }
      & .ant-form-item-label {
        text-align: initial;
      }
    `}
`;

FormItem.defaultProps = {};

/** @component */
export default FormItem;
