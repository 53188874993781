import styled, { css } from 'styled-components';
import { Typography } from 'antd';

const { Title: title } = Typography;
const Title = styled(title)`
  margin-bottom: 30px;
  color: #101d5b;
  font-weight: 500;
  ${props =>
    props.level === 1 &&
    css`
      font-size: 32px;
      line-height: 37px !important;
    `}
  ${props =>
    props.level === 2 &&
    css`
      font-size: 24px !important;
      line-height: 28px !important;
    `}
  ${props =>
    props.level === 3 &&
    css`
      font-size: 18px !important;
      line-height: 21px !important;
    `}
  ${props =>
    props.level === 4 &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
  ${props =>
    props.level === 5 &&
    css`
      font-size: 14px !important;
      line-height: 16px !important;
    `}
    ${props =>
    props.size_level === 6 &&
    css`
      font-size: 10px !important;
      line-height: 11px !important;
    `}
  ${props =>
    props.level === 4 &&
    props.size_type === 'responsive' &&
    css`
      font-size: 14px;
      line-height: 24px;
    `}
  ${props =>
    props.level === 3 &&
    props.size_type === 'responsive' &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
  ${props =>
    props.level === 2 &&
    props.size_type === 'responsive' &&
    css`
      font-size: 18px;
      line-height: 16px;
    `}
  ${props =>
    props.level === 1 &&
    props.size_type === 'responsive' &&
    css`
      font-size: 24px;
      line-height: 28px;
    `}
  ${props =>
    props.mob_level === 2 &&
    css`
      @media screen and (max-width: 576px) {
        font-size: 24px;
        line-height: 28px;
      }
    `}
    ${props =>
    props.popins_level === 1 &&
    css`
      font-family: 'Poppins', sans-serif;
      font-size: 14px !important;
      line-height: 21px !important;
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px !important;
    `}
    ${props =>
    (props.ml || props.ml === 0) &&
    css`
      margin-left: ${props.ml}px !important;
    `}
      ${props =>
    (props.mr || props.mr === 0) &&
    css`
      margin-right: ${props.mr}px !important;
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    (props.ml || props.ml === 0) &&
    css`
      margin-left: ${props.ml}px !important;
    `}
    ${props =>
    (props.mr || props.mr === 0) &&
    css`
      margin-right: ${props.mr}px !important;
    `}
  ${props =>
    props.color
      ? css`
          color: ${props.color} !important;
        `
      : css`
          color: #1d384b !important;
        `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  
    ${props =>
    props.border_bottom &&
    css`
      border-bottom: 1px solid ${props.border_bottom};
    `}
    ${props =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `}
      ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
    ${props =>
    props.lh &&
    css`
      line-height: ${props.lh} !important;
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
    ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height};
    `}
  ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw} !important;
    `}
    ${props =>
    props.fz &&
    css`
      font-size: ${props.fz} !important;
    `}
    ${props =>
    props.text_dec &&
    css`
      text-decoration: ${props.text_dec};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt}px !important;
    `}
    ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.pb &&
    css`
      padding-bottom: ${props.pb};
    `}
    ${props =>
    props.pr &&
    css`
      padding-right: ${props.pr};
    `}
    ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
    ${props =>
    props.white_space &&
    css`
      white-space: ${props.white_space};
    `}
    ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
    ${props =>
    props.type === 'textOverflow' &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px !important;
      line-height: 16px !important;
      font-weight: 400 !important;
      margin: 0 5px;
    `}
    ${props =>
    props.tree_dots &&
    css`
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
    ${props =>
    props.type === 'Order-dropDown' &&
    css`
      width: 121px;
      height: 49px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      cursor: inherit;
    `}
    ${props =>
    props.after_color &&
    css`
      &::after {
        background: ${props.after_color};
      }
    `}
      ${props =>
    props.type === 'services-info' &&
    css`
      font-weight: 500 !important;
      font-size: 13px !important;
      line-height: 15px !important;
      color: #b2b7c2 !important;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 3px;
        left: -19px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    `}
`;

/** @component */
export default Title;
