import styled, { css } from 'styled-components';
import { Input as input } from 'antd';

const Input = styled(input)`
  background: #fff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  height: 34px;
  padding: 0 15px;
  font-weight: 300;

  &:hover {
    border-color: #000;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #c4c4c4;
    outline: 0;
    padding: 5px;
    box-shadow: none !important;
  }
  box-shadow: none !important;

  &[disabled] {
    color: #cecece;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080;
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
  ${props =>
    props.input_padding &&
    css`
      input {
        padding: ${props.input_padding};
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fz &&
    css`
      font-size: ${props.fz}px !important;
    `}
  ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw} !important;
    `}
    ${props =>
    props.lh &&
    css`
      line-heigth: ${props.lh}px !important;
    `} 
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
    ${props =>
    props.border_bottom &&
    css`
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid ${props.border_bottom};
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
    ${props =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
`;

export default Input;
