import { notification } from 'antd';
import client from './main-client';

export const fetchSortOrdersApi = params => client().get(`/orders`, { params });
export const fetchOrdersApi = params =>
  client().get(`/orders?Status=New&Status=InProgress&Status=TaskCompleted&_sort=created_at:DESC`, {
    params,
  });
export const fetchReminderApi = params => client().get(`/reminders`, { params });
export const UpdateReminderApi = params => client().put(`/reminders/${params.id}`, params.values);
export const CreateReminderApi = params => client().post(`/reminders`, params);
export const deleteReminderApi = params => client().delete(`/reminders/${params}`);
export const UpdateOrdersApi = params => client().put(`/orders/${params?.id}`, params?.values);
export const DeleteOrdersApi = id => client().delete(`/orders/${id}`);
export const fetchAllOrdersCountApi = params =>
  client().get('/orders/count?Status=New&Status=InProgress&Status=TaskCompleted', { params });
export const fetchAllTechnicianNameApi = params => client().get(`/users?_q=${params}&role.id=4`);
export const fetchAllUsersApi = params => client().get(`/users`, { params });

// export const fetchAllCustomerNameApi = params => client().get(`/users?role.id=3`);
export const fetchAllUserCountApi = type => client().get(`/users/count?role.id=${type}`);

export const fetchOneOrderApi = async id => client().get(`/orders/${id}`);

export const fetchOneOrderServicesApi = id => client().get(`/service-details?Order.id=${id}`);
export const fetchOneOrderServicesCountApi = id =>
  client().get(`/service-details/count?Order.id=${id}`);
export const fetchOneOrderCommentApi = id => client().get(`/order-details?Order.id=${id}`);
export const fetchOneOrderCommentCountApi = id =>
  client().get(`/order-details/count?Order.id=${id}`);
export const createTechnicianApi = data => client().post('/auth/local/register', data);
export const updateTechnicianApi = params => client().put(`/users/${params.id}`, params.values);
export const deleteTechnicianApi = params => client().delete(`/users/${params}`);

export const updateOneOrderApi = params => client().put(`/orders/${params.id}`, params.values);
export const addOrderApi = data => client().post('/orders', data);
export const deleteOrderApi = params => client().delete(`/orders/${params.id}`, { params });

export const updateOneSettingsApi = data => client().put(`/setting`, data);
export const createOneSettingsApi = data => client().post(`/setting`, data);
export const getOneSettingsApi = data => client().get(`/setting`, data);

export const servicesApi = params => client().get('/services', { params });
export const UpdateServicesApi = params => client().put(`/services/${params.id}`, params.values);
export const createServicesApi = params => client().post(`/services`, params);
export const delateServicesApi = id => client().delete(`/services/${id}`);

export const categoriesApi = params => client().get('/service-categories', { params });
export const updateCategoriesApi = params =>
  client().put(`/service-categories/${params.id}`, params.values);
export const fetchAllCategoriesCountApi = params =>
  client().get(`/service-categories/count`, { params });
export const createCategoriesApi = params => client().post(`/service-categories`, params);
export const delateCategoriesApi = id => client().delete(`/service-categories/${id}`);

export const calendarSettingsApi = params => client().get('/calendar-setting', { params });
export const updateCalendarSettingsApi = params => client().put(`/calendar-setting`, params);
export const fetchAllCalendarSettingsCountApi = params =>
  client().get(`/calendar-setting/count`, { params });
export const createCalendarSettingsApi = params => client().post(`/calendar-setting`, params);
export const delateCalendarSettingsApi = id => client().delete(`/calendar-setting/${id}`);

export const getOneServicesFeesApi = params => client().get(`/service-fees`, { params });
export const createOneServicesFeesApi = params => client().post(`/service-fees`, params);
export const deleteOneServicesFeesApi = id => client().delete(`/service-fees/${id}`);
export const UpdateOneServicesFeesApi = params =>
  client().put(`/service-fees/${params.id}`, params.values);

export const checkIsServicesInUserCartApi = id => client().get(`/user-carts?services=${id}`);
export const checkIsServicesInServicesDetailsApi = id =>
  client().get(`/service-details?Service=${id}`);

export const addUploadApi = data => {
  const formData = new FormData();
  formData.append('files', data);
  return client({ 'Content-type': 'application/x-www-form-urlencoded' })
    .post('/upload', formData)
    .then(res => res)
    .catch(() => {
      notification.error({ description: 'Something went wrong!' });
      return null;
    });
};
