import React from 'react';
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const OpenNotification = text => {
  if (text) {
    notification.open({
      message: 'Information',
      description: text,
      icon: <ExclamationCircleOutlined style={{ color: 'rgb(126 150 239)' }} />,
      maxCount: 1,
    });
  } else if (!navigator.onLine) {
    notification.open({
      message: 'Information',
      description: 'Something went wrong',
      icon: <ExclamationCircleOutlined style={{ color: 'rgb(126 150 239)' }} />,
      maxCount: 1,
    });
  }
};
