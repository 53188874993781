import styled, { css } from 'styled-components';
import { Menu as menu } from 'antd';

const Menu = styled(menu)`
  ${props =>
    props.type === 'ordersSort' &&
    css`
      z-index: 10 !important;
      & .ant-menu-item {
        height: fit-content;
        background: white !important;
        & .ant-radio {
          width: 16px;
        }
        & .ant-radio-inner {
          border-color: #717579 !important;
          &::after {
            background: #717579 !important;
          }
        }
        & .ant-radio-wrapper {
          margin: 8px 0;
        }
      }
      margin-top: 14px;
      & ant-menu-item-active {
        & .ant-checkbox {
          border: none;
        }
      }
      & li {
        height: fit-content;
        & .ant-menu-item {
          height: fit-content;
        }
        & .ant-radio-group {
          display: flex;
          flex-direction: column;
        }
        & .ant-radio-wrapper {
          & span:last-child {
            padding-left: 16px;
            width: 100%;
          }
          & .ant-radio-inner {
            width: 16px !important;
          }
        }
        z-index: 11 !important;
        & span,
        & span h1 {
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          color: #717579 !important;
        }
        & span h1 {
          margin-top: 2px;
          width: 100%;
          height: 100%;
        }
      }
    `}
  ${props =>
    props.type === 'sidebar' &&
    css`
      position: sticky;
      top: 0;
      border-right: 0;
      padding-top: 30px !important;
      background: #1d384b !important;
      .ant-menu {
        background: #1d384b !important;
      }
      & .ant-menu-item .ant-menu-title-content .logo {
        width: auto;
        overflow: visible;
        margin-bottom: 30px;
        & svg,
        & path {
          overflow: visible;
        }
      }
      &.ant-menu-inline-collapsed .logo {
        padding-left: 10px;
      }
      & .ant-menu-item-only-child {
        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title {
          background-color: transparent !important;
          & span svg {
            fill: initial !important;
          }
          & .ant-menu-title-content span:first-child {
            color: inherit !important;
          }
          box-shadow: none;
          background: none;
        }
      }
      .ant-menu-item {
        color: #717579;
        max-width: 100%;

        .case_icon svg {
          width: 18px;
        }
        .ant-menu-item-icon {
          width: 24px;
        }
        &:after {
          border-right: none !important;
        }

        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title {
          background-color: transparent !important;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          & span svg path {
            fill: #09bd3c;
          }
          & .ant-menu-title-content span:first-child {
            color: #09bd3c !important;
          }
          box-shadow: none;
          color: #09bd3c;
          background: none;
        }
      }

      .ant-menu-sub {
        background-color: #fff;

        .ant-menu-item {
          padding-left: 65px !important;
        }
      }
      .ant-menu-item,
      .ant-menu-submenu {
        font-weight: 500;
        font-size: 16px;
        svg {
          font-size: 18px;
        }
      }
      .ant-menu-item:active,
      .ant-menu-submenu-title:active {
        background-color: #fff;
      }
      .ant-menu-submenu {
        color: #717579 !important;
        font-weight: 500 !important;
      }
      .ant-menu-submenu-title:hover {
        color: #886cc0 !important;

        path {
          fill: #886cc0;
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-arrow {
          color: #717579 !important;
        }
        &:hover {
          .ant-menu-submenu-title > .ant-menu-submenu-arrow {
            color: #886cc0 !important;
            path {
              fill: #886cc0;
            }
          }
        }
      }
    `}
  ${props =>
    props.type === 'Order-Table' &&
    css`
      & .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title {
        padding: 0 !important;
      }
      .ant-dropdown-menu {
        padding: 0 !important;
      }
    `}
  ${props =>
    props.type === 'dashbordMenu' &&
    css`
      border-right: 0;
      background-color: #1d384b;
      padding-top: 20px;
      & .ant-menu-item:first-child {
        margin-left: 32px;
        margin-bottom: 30px;
      }
      & .ant-menu-item {
        padding: 0;
      }
    `}
  ${props =>
    props.type === 'sidebar' &&
    props.sec_type === 'collapsed' &&
    css`
      background-color: #1d384b !important;
      .ant-menu {
        background-color: #1d384b !important;
      }
      padding-top: 20px;
      & .ant-menu-item:first-child {
        margin-left: 32px;
        margin-bottom: 30px;
        padding: 0 !important;
      }
      & .ant-menu-item {
        padding: 0;
      }
      .ant-menu-item {
        &.ant-menu-item-selected,
        &:active,
        .ant-menu-submenu-title:active {
          width: 100%;
          border-radius: 0;
        }
      }
    `}
    ${props =>
    props.padding &&
    css`
      & .ant-menu-submenu-title {
        padding: ${props.padding} !important;
      }
    `}
    ${props =>
    props.title_padding &&
    css`
      & .ant-menu-title-content .ant-layout-content {
        padding: 0 !important;
      }
      & .ant-menu-title-content .ant-layout-content h3 {
        padding: ${props.title_padding};
      }
    `}
    ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt} !important;
    `}
    ${props =>
    props.type === 'MobileStorage' &&
    css`
      background: #ffffff;
      backdrop-filter: blur(8px);
      border-radius: 10px;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
      margin-top: 32px;
      & .ant-menu-submenu-open {
        & .ant-menu-submenu-expand-icon svg {
          transform: rotate(179deg);
        }
      }
      & .ant-menu-item{
        background: white !important;
        cursor:inherit;
      }
      & .ant-menu-submenu-title {
        height: auto !important;
        margin: 0;
        padding: 0;
        padding-top: 32px;
        padding-right: 16px;
        padding-left: 0 !important;
        display: flex;
        align-items: start !important;
        & .ant-menu-submenu-expand-icon {
          position: absolute;
          top: 50%;
          right: 10%;
          & svg {
            transition: all 0.3s;
          }
        }
      }
      & .ant-menu-sub {
        background: white;
        margin: 0;
        border-radius: 10px;
      }
      & .ant-menu-item {
        padding: 0 !important;
        display: inline-block !important;
        width 100% !important; 
        height: auto !important;
        margin: 0 !important;
      }
    `}
`;

export default Menu;
