import styled, { css } from 'styled-components';

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  ${props =>
    props.color &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        border-radius: 0px 20px 20px 0px;
        background: ${props.color};
      }
    `}
  ${props =>
    props.br &&
    css`
      border-radius: ${props.br};
    `}
  ${props =>
    props.bi &&
    css`
      background-image: url(${props.bi});
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
      ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
      ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
      ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
      ${props =>
    props.bc &&
    css`
      background-color: ${props.bc};
    `}
      ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
      ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
      ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
`;

export default Image;
