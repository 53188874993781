import styled, { css } from 'styled-components';
import { Button as button } from 'antd';

const Button = styled(button)`
  background: #886cc0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  height: 35px;
  border: none;
  font-weight: 500;
  color: #fff;
  padding: 0 16px;
  font-size: 14px;
  &[disabled] {
    background: #c4c4c4;
    color: white;
  }
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius} !important;
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
    ${props =>
    props.fw &&
    css`
      & span {
        font-weight: ${props.fw} !important;
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor} !important;
    `}
    ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
      & span {
        color: ${props.color} !important;
      }
    `}
  ${props =>
    props.icon_height &&
    css`
      svg {
        height: ${props.icon_height};
      }
    `}
    ${props =>
    props.dashMenuNot &&
    css`
      position: realitive;
      &::after {
        content: ${props.dashMenuNot};
      }
    `}
    ${props =>
    props.type === 'pending' &&
    css`
      background: transparent;
      border: 1px solid #fbbb00;
      border-radius: 25px;
      width: 64px;
      height: 24px;
      color: #1d384b;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 89px;
      & span {
        font-weight: 500 !important;
        font-size: 12px;
        line-height: 14px !important;
      }
    `}
    ${props =>
    props.type === 'received' &&
    css`
      background: transparent;
      border: 1px solid #95d600;
      border-radius: 25px;
      box-shadow: none;
      height: 30px;
      color: #95d600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      & span {
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 16px !important;
      }
      &:hover,
      &:active,
      &:focus {
        background: transparent !important;
        & span {
          color: #1d384b !important;
        }
      }
    `}
    ${props =>
    props.type === 'additionalFee' &&
    css`
      background: transparent;
      border: 1px solid #f0f1f4;
      border-radius: 10px;
      box-shadow: none;
      height: 30px;
      color: #95d600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      & span {
        font-weight: 500 !important;
        font-size: 14px;
        color: #95d600;
        line-height: 16px !important;
      }
      &:hover,
      &:active,
      &:focus {
        background: transparent !important;
        & span {
          color: #95d600 !important;
        }
      }
    `}
    ${props =>
    props.type === 'reject' &&
    css`
      background: transparent;
      border: 1px solid #f03a5b;
      border-radius: 25px;
      box-shadow: none;
      display: flex;
      width: 102px;
      height: 41px;
      align-items: center;
      justify-content: center;
      & span {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px !important;
        color: #f03a5b;
      }
      &:hover,
      &:active,
      &:focus {
        background: transparent !important;
        & span {
          color: #1d384b !important;
        }
      }
      &[disabled] {
        & span {
          color: #1d384b !important;
        }
      }
    `}
      ${props =>
    props.type === 'accept' &&
    css`
      background: #95d600;
      border: none;
      border-radius: 25px;
      box-shadow: none;
      width: 102px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      & span {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px !important;
        color: white;
      }
      &:hover,
      &:active,
      &:focus {
        background: #95d600;
        & span {
          color: white !important;
        }
      }
    `}
    ${props =>
    props.type === 'acceptTime' &&
    css`
      background: #95d600;
      border: none;
      border-radius: 25px;
      box-shadow: none;
      width: 102px;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      & span {
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 16px !important;
        color: white;
      }
      &:hover,
      &:active,
      &:focus {
        background: #95d600;
        & span {
          color: white !important;
        }
      }
    `}
    ${props =>
    props.type === 'conflict' &&
    css`
      background: transparent;
      border: 1px solid #f03a5b;
      border-radius: 25px;
      box-shadow: none;
      height: 30px;
      color: #f03a5b;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      & span {
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 16px !important;
      }
    `}

  ${props =>
    props.type === 'primary' &&
    css`
      background: #886cc0;
      &:hover {
        background: #756399;
        color: #fff;
      }
      &:active {
        background: #886cc0;
        color: #fff;
      }
      &:focus {
        background: #886cc0;
        color: #fff;
      }
      &[disabled] {
        background: #e7e7e7;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      background: #000080;

      &:hover {
        background: #0202a5;
        color: #fff;
      }

      &:active {
        background: #000080;
        color: #fff;
      }

      &:focus {
        background: #000080;
        color: #fff;
      }

      &[disabled] {
        background: #000080;
        color: #616161;
      }
    `}
    ${props =>
    props.type === 'green' &&
    css`
      background: #95d600;
      & span {
        color: white;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
      }
      &:hover {
        background: #95d600;
        color: #fff;
      }

      &:active {
        background: #000080;
        color: #fff;
      }

      &:focus {
        background: #95d600;
        color: #fff;
      }

      &[disabled] {
        background: #95d600;
        color: #616161;
      }
    `}
  ${props =>
    props.type === 'grey' &&
    css`
      background: #c4c4c4;
      color: #fff;

      &:hover {
        background: #0202a5;
        color: #fff;
      }

      &:active {
        background: #c4c4c4;
        color: #fff;
      }

      &:focus {
        background: #c4c4c4;
        color: #fff;
      }

      &[disabled] {
        background: #c4c4c4;
        color: #616161;
      }
    `}
  ${props =>
    props.sec_type === 'ghost' &&
    css`
      color: #886cc0;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #756399;
      }
      &:active {
        background: transparent;
        color: #756399;
      }
      &:focus {
        background: transparent;
        color: #756399;
      }
    `}
  ${props =>
    props.sec_type === 'blue_ghost' &&
    css`
      color: #000080;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #000080;
      }
      &:active {
        background: transparent;
        color: #000080;
      }
      &:focus {
        background: transparent;
        color: #000080;
      }
    `}
  ${props =>
    props.sec_type === 'secondary' &&
    css`
      color: #717579;
      &:hover {
        color: #717579;
      }
      &:active {
        color: #717579;
      }
      &:focus {
        color: #717579;
      }
    `}
  ${props =>
    props.type === 'blue_outline' &&
    css`
      background: none;
      border: 1px solid #000080;
      color: #000080;
      &:hover {
        color: #000080;
        border-color: #000080;
      }
      &:active {
        color: #000080;
        border-color: #000080;
      }
      &:focus {
        color: #000080;
        border-color: #000080;
      }
    `}
  ${props =>
    props.type === 'outline' &&
    css`
      background: none;
      border: 1px solid ${props.bordercolor};
      //border-radius: 50px;
      color: #886cc0;
      &:hover {
        color: #6138fb;
        border-color: #6138fb;
      }
      &:active {
        color: #886cc0;
        border-color: #886cc0;
      }
      &:focus {
        color: #886cc0;
        border-color: #886cc0;
      }
    `}
  ${props =>
    props.type === 'dark_outline' &&
    css`
      background: #fff;
      border: 1px solid #eeeeee;
      //border-radius: 10px;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
  ${props =>
    props.type === 'black_outline' &&
    css`
      background: #fff;
      border: 1px solid #171b1e;
      color: #171b1e;
      box-shadow: none;
      &:hover {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:active {
        color: #171b1e;
        border-color: #171b1e;
      }
      &:focus {
        color: #171b1e;
        border-color: #171b1e;
      }
    `}
  ${props =>
    props.type === 'destructive' &&
    css`
      background: #f8817b;
      border: 1px solid #f8817b;
      //border-radius: 10px;
      color: #fff;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_destructive' &&
    css`
      border: 1px solid #c4c4c4;
      box-shadow: none;
      color: #ff2222;
      background: #c4c4c4;
      &:hover {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:active {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
      &:focus {
        color: #ff2222;
        background: #c4c4c4;
        border: 1px solid #c4c4c4;
      }
    `}
  ${props =>
    props.type === 'destructive_outline' &&
    css`
      background: #fff;
      border: 1px solid #f8817b;
      //border-radius: 10px;
      color: #f8817b;
      box-shadow: none;
      &:hover {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:active {
        color: #ff4f46;
        border-color: #ff4f46;
      }
      &:focus {
        color: #ff4f46;
        border-color: #ff4f46;
      }
    `}
  ${props =>
    props.type === 'grey_ghost' &&
    css`
      color: #717579;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #717579;
      }
      &:active {
        background: transparent;
        color: #717579;
      }
      &:focus {
        background: transparent;
        color: #717579;
      }
    `}
  ${props =>
    props.type === 'dark_ghost' &&
    css`
      color: #171b1e;
      background: transparent;
      box-shadow: none;
      &:hover {
        background: transparent;
        color: #171b1e;
      }
      &:active {
        background: transparent;
        color: #171b1e;
      }
      &:focus {
        background: transparent;
        color: #171b1e;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
      &:hover,
      &:focused,
      &:active {
        color: ${props.color} !important;
      }
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
    ${props =>
    props.back_color &&
    css`
      background: ${props.back_color} !important;
      &:hover,
      &:focused,
      &:active {
        background: ${props.back_color} !important;
      }
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height};
    `}
    ${props =>
    props.border_color &&
    css`
      border-color: ${props.border_color};
    `}
`;

export default Button;
