import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OpenNotification } from 'components/molecules/openNotification/index';
import { useGetCustomerCountQuery } from 'app/services/count/customer';
import LogOutIcon from 'assets/custom-icons/LogOut';
import { Key } from 'Utils/Helpers';
import LogoIcon from 'assets/custom-icons/LogoIcon';
import { removeUser } from 'app/slices/user';
import { collapsedChange } from 'app/slices/menuOpen';
import { fetchAllUserCountApi } from 'Utils/api-call-services/order';
import useApiCall from 'Utils/hooks/useApiCall';
import { Count } from 'app/slices/technicianCount';
import { protectedRouts } from '../../constants/routes';
import { Layout, Menu, Sider, MenuItem } from '../atoms';
import { getUserRole } from '../../app/services/selectorHelpers';
import DashHeader from './dashboard/Header';

const { SubMenu } = Menu;

const DashboardWrapper = ({ routeData, children }) => {
  const dispatch = useDispatch();
  const { data: CustomerCount } = useGetCustomerCountQuery();
  const TechnicianCountState = useSelector(state => state?.TechnicianCount);
  const [TechnicianCount, , TechnicianCountCall] = useApiCall(fetchAllUserCountApi);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const role = useSelector(getUserRole);
  const collapsed = useSelector(state => state.menuOpen);
  const collapsedText = collapsed ? 'collapsed' : '';
  const handleLogout = () => {
    dispatch(removeUser());
    navigate('/sign-in');
  };
  const handleClickLogo = () => {
    navigate('/dashboard');
  };
  React.useEffect(() => {
    OpenNotification();
    TechnicianCountCall('4');
  }, []);
  React.useEffect(() => {
    if (TechnicianCount >= 0 && typeof TechnicianCount === 'number') {
      dispatch(Count(TechnicianCount));
    }
  }, [TechnicianCount]);
  return (
    <Layout overflow='hidden'>
      <Sider
        back_color='#1d384b'
        trigger={null}
        collapsible
        collapsed={collapsed?.burger_click ? false : collapsed?.flag}
        theme='light'
        width={352}
        collapsedWidth={56}
        shadow='7px 0 80px rgba(0, 0, 0, 0.05)'
        onMouseEnter={() =>
          !collapsed?.burger_click && dispatch(collapsedChange({ ...collapsed, flag: false }))
        }
        onMouseLeave={() =>
          !collapsed?.burger_click && dispatch(collapsedChange({ ...collapsed, flag: true }))
        }
      >
        <Menu
          mode='inline'
          selectedKeys={pathname}
          defaultOpenKeys={[(!collapsed && routeData?.subKey) || '']}
          type='sidebar'
          sec_type={collapsedText}
        >
          <MenuItem key='logo' type='firstSide' onClick={handleClickLogo}>
            <LogoIcon className='logo' />
          </MenuItem>
          {Object.values(protectedRouts[role]).map(menuitem =>
            menuitem.items ? (
              <SubMenu
                key={menuitem}
                icon={menuitem.icon}
                title={menuitem?.menuTitle || menuitem?.pageTitle}
              >
                {Object.values(menuitem.items).map(subItem => (
                  <Menu.Item
                    key={subItem?.pathname ? subItem?.pathname : Key()}
                    onClick={() => {
                      navigate(subItem.pathname);
                    }}
                  >
                    {subItem.menuTitle || subItem.pageTitle}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              !menuitem.hideInSidebar && (
                <MenuItem
                  type='SideChild'
                  key={menuitem.pathname}
                  icon={menuitem.icon}
                  onClick={() => {
                    !collapsed?.burger_click &&
                      dispatch(collapsedChange({ ...collapsed, flag: true }));
                    navigate(menuitem.pathname);
                  }}
                >
                  <span>{menuitem?.menuTitle || menuitem?.pageTitle}</span>
                  {menuitem?.content && (
                    <span className='count'>
                      {(menuitem.content === 'Technician' && TechnicianCountState) ||
                        (menuitem.content === 'Customer' && CustomerCount)}
                    </span>
                  )}
                </MenuItem>
              )
            )
          )}
          <MenuItem
            icon={<LogOutIcon />}
            align='center'
            type='SideChild'
            key='log_Out'
            onClick={handleLogout}
          >
            <span>Log Out</span>
          </MenuItem>
        </Menu>
      </Sider>
      <Layout back_color='#F5F8FD' height={pathname === '/technician' ? '100%' : '100%'}>
        <DashHeader Text={routeData.pageTitle} setCollapsed={collapsedChange} />
        {children}
      </Layout>
    </Layout>
  );
};

export default DashboardWrapper;
