import { IconWrapper } from '../../components/atoms';

const TechnicianSvg = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.0678 9.58736C11.2989 9.46948 11.5561 9.40703 11.8229 9.40703C12.2656 9.40703 12.6814 9.57863 12.995 9.88936L13.67 9.21645C15.3403 9.80052 17.2726 9.42639 18.6079 8.0948C19.7315 6.97411 20.1747 5.43182 19.9379 3.97837C19.9119 3.81854 19.7982 3.68623 19.6435 3.6365C19.489 3.58658 19.3193 3.6272 19.2043 3.74166L18.202 4.74144C17.3856 5.55577 16.0623 5.55577 15.2461 4.74144C14.4303 3.92712 14.4303 2.6073 15.2461 1.79297L16.2484 0.793568C16.3633 0.678537 16.4044 0.509407 16.3542 0.355084C16.3041 0.20057 16.1719 0.0876273 16.0113 0.0616219C14.5539 -0.174324 13.0083 0.267577 11.8846 1.38827C10.5293 2.74017 10.1645 4.70462 10.7877 6.38756L9.32373 7.84785L11.0678 9.58736Z'
      fill='white'
    />
    <path
      d='M8.40038 12.2339L6.66389 10.5018L0.592167 16.5582C-0.197389 17.3453 -0.197389 18.6225 0.592167 19.41C1.38172 20.1974 2.66168 20.1974 3.45124 19.41L8.68526 14.1888C8.15756 13.6594 8.06241 12.8603 8.40038 12.2339Z'
      fill='white'
    />
    <path
      d='M12.2731 10.6163C12.1488 10.4923 11.9863 10.4304 11.8232 10.4304C11.6605 10.4304 11.498 10.4925 11.3737 10.6163L11.0112 10.9777L4.55433 4.53748L4.85634 4.23623C5.13132 3.96194 5.09707 3.50732 4.78383 3.27707L2.14913 1.34186C1.89603 1.15602 1.54492 1.18241 1.32246 1.40412L0.203875 2.51988C-0.0185858 2.7414 -0.0448472 3.092 0.141457 3.34446L2.08176 5.97213C2.31259 6.28496 2.76817 6.31932 3.04335 6.04465L3.34535 5.7434L9.80187 12.1836L9.41403 12.5707C9.1655 12.8186 9.1655 13.22 9.41403 13.4677L15.3702 19.4093C16.1598 20.1967 17.44 20.1967 18.2295 19.4093C19.0189 18.6217 19.0189 17.3448 18.2295 16.5574L12.2731 10.6163Z'
      fill='white'
    />
  </svg>
);
const TechnicianIcon = props => <IconWrapper component={TechnicianSvg} {...props} />;

export default TechnicianIcon;
