import axios from 'axios';
import { notification } from 'antd';
import { getAccessToken, emptyState } from '../../configs/localStorage';
import { API_ROOT } from '../../configs/env-vars';

// eslint-disable-next-line import/no-anonymous-default-export
export default (headers = {}) => {
  const service = axios.create({
    baseURL: API_ROOT, // url of the api
    headers: {
      Authorization: getAccessToken() ? 'Bearer '.concat(getAccessToken()) : '',
      ...headers,
    },
  });
  service.interceptors.response.use(
    response => response,
    error => {
      const errorResponse = error.response;
      notification.error({ description: 'Something went wrong!' });
      if (errorResponse.status === 401) {
        emptyState();
      }
      throw error;
    }
  );
  return service;
};
