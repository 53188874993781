import { IconWrapper } from '../../components/atoms';

const ServicesSvg = () => (
  <svg
    width='22'
    height='16'
    viewBox='0 0 22 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.1781 12.7364L19.3781 14.1221C19.1609 14.4907 18.8062 14.7581 18.3919 14.8653C17.9777 14.9726 17.5378 14.911 17.1689 14.6941L6.58957 8.5861C5.93806 8.85715 5.22738 8.95452 4.52697 8.86871C3.82657 8.78289 3.16042 8.51681 2.59362 8.09649C2.02683 7.67616 1.57879 7.11597 1.29329 6.47067C1.00779 5.82536 0.89461 5.11702 0.964805 4.41488L4.42891 6.41488L6.02891 3.6436L2.5648 1.6436C3.13778 1.23174 3.80781 0.975584 4.50941 0.900178C5.21101 0.824772 5.92017 0.932691 6.56758 1.21339C7.21499 1.49408 7.7785 1.93795 8.20302 2.50161C8.62754 3.06527 8.89855 3.72943 8.98957 4.42918L19.5689 10.5372C19.9412 10.7482 20.2145 11.0983 20.3287 11.5107C20.4429 11.9231 20.3888 12.364 20.1781 12.7364Z'
      stroke='#F2994A'
      strokeWidth='1.5'
    />
  </svg>
);
const ServicesIcon = props => <IconWrapper component={ServicesSvg} {...props} />;

export default ServicesIcon;
