import { createCustomApi } from 'configs/rtk-query';

export const CustomerApi = createCustomApi({
  reducerPath: 'customerCount',
  endpoints: builder => ({
    getCustomerCount: builder.query({
      query: id => `/users/count?role.id=3`,
    }),
  }),
});

export const { useGetCustomerCountQuery } = CustomerApi;
