import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Content = styled(layout.Content)`
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.border_right &&
    css`
      border-right: 1px solid ${props.border_right};
    `}
    ${props =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `}
       
  
    ${props =>
    props.border_bottom &&
    css`
      border-bottom: 1px solid ${props.border_bottom};
    `}
    ${props =>
    props.border_top &&
    css`
      border-top: 1px solid ${props.border_top};
    `}
   ${props =>
    props.pr &&
    css`
      padding-right: ${props.pr};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
    ${props =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
    ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
    ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
    ${props =>
    props.text_align &&
    css`
      text-align: ${props.text_align};
    `}
    ${props =>
    props.white_space &&
    css`
      white-space: ${props.white_space};
    `}
    ${props =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
    ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height};
    `}
    ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
    ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height};
    `}
      ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
      width: 100%;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.pt &&
    css`
      padding-top: ${props.pt};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.flex_wrap &&
    css`
      flex-wrap: ${props.flex_wrap};
    `}
    ${props =>
    props.border_radius &&
    css`
      border-radius: ${props.border_radius} !important;
    `}
    ${props =>
    props.position &&
    css`
      position: ${props.position};
    `}
    ${props =>
    props.type === 'charts' &&
    css`
      & .recharts-legend-wrapper {
        display: none;
      }
      & .recharts-wrapper,
      & .recharts-surface {
        width: fit-content !important;
        height: fit-content !important;
      }
    `}
    ${props =>
    props.type === 'TooltipWrapper' &&
    css`
      display: flex;
      align-items: center;
    `}
    ${props =>
    props.type === 'remainder-icon' &&
    css`
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 999;
    `}
    ${props =>
    props.type === 'grid' &&
    css`
      display: grid;
      grid-template-columns: 320px 320px 320px;
      grid-gap: 32px;
    `}
    ${props =>
    props.columns &&
    css`
      display: grid;
      grid-template-columns: ${props?.columns};
    `}
    ${props =>
    props.type === 'connect_in_table' &&
    css`
      & div div div > ul {
        & li {
          background: transparent;
          border: none;
          display: flex;
          align-items: center;
          min-width: auto !important;
          height: auto !important;
          & a {
            font-size: 14px;
            line-height: 16px !important;
            font-weight: 500;
            color: #1d384b;
          }
        }
        & li:first-child {
          margin-right: 11px !important;
        }
        & li:last-child {
          margin-left: 11px !important;
        }
      }
    `}
    ${props =>
    props.type === 'technician-styles' &&
    css`
      &:hover {
        background: #f5f8fd;
      }
    `}
    ${props =>
    props.type === 'dash-con' &&
    css`
      background: #ffffff;
      backdrop-filter: blur(8px);
      border-radius: 20px;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    `}
    ${props =>
    props.type === 'log-out' &&
    css`
      & button {
        position: fixed;
        left: 62px;
        bottom: 30px;
        z-index: 9;
      }
    `}
`;

export default Content;
