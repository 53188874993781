import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { CustomerApi } from './services/count/customer';
import { authApi } from './services/users/auth';
import { usersApi } from './services/users/users';

import user from './slices/user';
import menuOpen from './slices/menuOpen';
import TechnicianCount from './slices/technicianCount';

export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [CustomerApi.reducerPath]: CustomerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    user,
    menuOpen,
    TechnicianCount,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      usersApi.middleware,
      authApi.middleware,
      CustomerApi.middleware,
    ]),
});

setupListeners(store.dispatch);
