import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

const MaskedInput = styled(InputMask)`
  border: 1px solid #e8eef4;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  border-radius: 0px !important;
  &[disabled] {
    background: #fff;
    color: rgba(0, 0, 0, 0.85);
    cursor: initial;
  }
  &::placeholder {
    color: #cecece;
  }
  ${props =>
    props.type === 'search' &&
    css`
      height: 60px;
      font-weight: 500;
      color: #101d5b;
      border-radius: 0;
      border: none !important;
    `}
  ${props =>
    props.border_bottom &&
    css`
      border: none;
      border-bottom: 1px solid ${props.border_bottom};
      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        box-shadow: none !important;
        outline: none !important;
      }
    `}
        ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${props =>
    props.type === 'tableSearch' &&
    css`
      width: 330px;
      height: 35px;
      border: none !important;
      background-color: #f6f9fc;
      padding: 5px 15px;
      color: #7e8299;
      .ant-input-prefix {
        margin-right: 10px;
      }
      input {
        background-color: #f6f9fc;
        color: #7e8299;
      }
    `}
  ${props =>
    props.type === 'chat' &&
    css`
      width: 100%;
      height: 60px;
      padding: 5px 15px;
      color: #7e8299;
      background: #fff;
      border: 1px solid #e8eef4;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      .ant-input-suffix svg {
        font-size: 18px;
      }
      input {
        background-color: #fff;
        color: #7e8299;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
      outline: 0;
      text-align: center;
      height: 48px;
      font-weight: 500;
      font-size: 18px;
      color: #101d5b;
      border-radius: 0;
    `}
  ${props =>
    props.only_width &&
    css`
      width: ${props.only_width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;
/** @component */
export default MaskedInput;
