import styled, { css } from 'styled-components';
import { Breadcrumb } from 'antd';

const BreadcrumbItem = styled(Breadcrumb.Item)`
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    (props.mr || props.mr === 0) &&
    css`
      margin-right: ${props.mr};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
    ${props =>
    props.border_bottom &&
    css`
      border-bottom: 1px solid ${props.border_bottom};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color};
    `} 
    ${props =>
    props.fz &&
    css`
      font-size: ${props.fz};
    `} 
    ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw};
    `} 
    ${props =>
    props.lh &&
    css`
      line-height: ${props.lh};
    `}
    ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
`;

export default BreadcrumbItem;
