import { IconWrapper } from '../../components/atoms';

const CustmerSvg = () => (
  <svg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.00186 9.63399C9.29076 9.63399 10.407 9.15944 11.3188 8.22277C12.2308 7.28644 12.6932 6.1405 12.6932 4.81683C12.6932 3.49369 12.2308 2.34774 11.3186 1.41108C10.4066 0.474754 9.29057 0 8.00186 0C6.71272 0 5.59676 0.474754 4.6849 1.41108C3.77285 2.3476 3.31055 3.49349 3.31055 4.81683C3.31055 6.1405 3.77285 7.28644 4.68509 8.22296C5.59709 9.15924 6.7131 9.63399 8.00186 9.63399Z'
      fill='white'
    />
    <path
      d='M16.2106 15.3789C16.1842 14.9892 16.1311 14.5641 16.0528 14.1153C15.9738 13.6631 15.872 13.2354 15.7501 12.8446C15.6242 12.4408 15.4529 12.0418 15.2414 11.6596C15.0218 11.2628 14.7638 10.9174 14.4744 10.6333C14.1716 10.3359 13.8011 10.0967 13.3725 9.92237C12.9453 9.74884 12.472 9.6609 11.9658 9.6609C11.7669 9.6609 11.5747 9.74464 11.2033 9.99298C10.9749 10.1459 10.7074 10.3229 10.4091 10.5188C10.1539 10.6857 9.80835 10.8421 9.38134 10.9836C8.96487 11.1221 8.5419 11.1923 8.12453 11.1923C7.70716 11.1923 7.28438 11.1221 6.86739 10.9836C6.4409 10.8423 6.09519 10.6859 5.84036 10.519C5.54482 10.325 5.27734 10.148 5.04534 9.99278C4.67438 9.74464 4.48194 9.66071 4.28322 9.66071C3.77664 9.66071 3.30355 9.74884 2.87672 9.92237C2.44837 10.0965 2.0776 10.3357 1.77465 10.6335C1.48525 10.9178 1.22723 11.263 1.00787 11.6596C0.796499 12.0418 0.625216 12.4407 0.499298 12.8448C0.377422 13.2356 0.275613 13.6631 0.196676 14.1153C0.11831 14.5635 0.0651941 14.9889 0.0388026 15.3795C0.0129818 15.7621 0 16.1594 0 16.5605C0 17.6044 0.323165 18.4497 0.960507 19.0731C1.59 19.688 2.42293 20 3.43565 20H12.8142C13.8271 20 14.6596 19.6881 15.2893 19.0731C15.9268 18.4501 16.25 17.6048 16.25 16.5603C16.2498 16.1573 16.2367 15.7598 16.2106 15.3789Z'
      fill='white'
    />
  </svg>
);
const CustmerIcon = props => <IconWrapper component={CustmerSvg} {...props} />;

export default CustmerIcon;
