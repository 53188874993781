import { SettingFilled, BarcodeOutlined } from '@ant-design/icons';
import CalendarIcon from 'assets/custom-icons/Calendar';
import SettingsIcon from 'assets/custom-icons/Settings';
import CustmerIcon from 'assets/custom-icons/Custmers';
import HomeIcon from 'assets/custom-icons/HomeIcon';
import ReminderIcon from 'assets/custom-icons/Reminder';
import OrderIcon from 'assets/custom-icons/Orders';
import TechnicianIcon from 'assets/custom-icons/Technician';
import ServicesIcon from 'assets/custom-icons/Services';
import ServiceCategoryIcon from 'assets/custom-icons/service-category-icon';
import CalendarSettingIcon from 'assets/custom-icons/CalendarSetting';

export const protectedRouts = {
  decorselection: {
    dashboard: {
      pageTitle: 'Dashboard',
      key: 'dashboard',
      icon: <SettingFilled />,
      pathname: '/dashboard',
      component: 'pages/dashboard-pages/dashboard',
    },
  },
  Authenticated: {
    dashboard: {
      pageTitle: 'Dashboard',
      pathname: '/dashboard',
      key: 'dashboard',
      icon: <HomeIcon />,
      component: 'pages/dashboard-pages/dashboard',
    },
    orders: {
      pageTitle: 'Orders',
      pathname: '/orders',
      key: 'orders',
      icon: <OrderIcon />,

      component: 'pages/dashboard-pages/orders',
    },
    Technicians: {
      pageTitle: 'Technicians',
      pathname: '/technician',
      key: 'Technicians',
      content: 'Technician',
      icon: <TechnicianIcon />,
      component: 'pages/dashboard-pages/technician',
    },
    Customers: {
      pageTitle: 'Customers',
      pathname: '/customers',
      key: 'Customers',
      icon: <CustmerIcon />,
      content: 'Customer',
      component: 'pages/dashboard-pages/customers',
    },
    Category: {
      pageTitle: 'Service Categories',
      pathname: '/category',
      key: 'Service Categories',
      icon: <ServiceCategoryIcon />,
      component: 'pages/dashboard-pages/category',
    },
    Calendar: {
      pageTitle: 'Calendar',
      pathname: '/calendar',
      key: 'Calendar',
      icon: <CalendarIcon />,
      component: 'pages/dashboard-pages/calendar',
    },
    CalendarSetting: {
      pageTitle: 'Calendar Setting',
      pathname: '/calendar-setting',
      key: 'CalendarSetting',
      icon: <CalendarSettingIcon />,
      component: 'pages/dashboard-pages/calendar-settings',
    },
    Services: {
      pageTitle: 'Services',
      pathname: '/services',
      key: 'Services',
      icon: <ServicesIcon color='white' />,
      component: 'pages/dashboard-pages/services',
    },
    Reminders: {
      pageTitle: 'Reminders',
      pathname: '/reminder',
      key: 'Reminders',
      icon: <ReminderIcon />,
      component: 'pages/dashboard-pages/reminder',
    },
    Settings: {
      pageTitle: 'Settings',
      pathname: '/settings',
      key: 'settings',
      icon: <SettingsIcon />,
      component: 'pages/dashboard-pages/settings',
    },
    orderDetails: {
      pageTitle: 'Order details',
      pathname: '/order-details/:id',
      key: 'Order-details',
      hideInSidebar: true,
      component: 'pages/dashboard-pages/OrderDetails',
    },
    Coupons: {
      pageTitle: 'Coupons',
      pathname: '/coupons',
      key: 'Coupons',
      icon: <BarcodeOutlined />,
      component: 'pages/dashboard-pages/coupons',
    },
  },
};

const routes = {
  signIn: {
    pageTitle: 'Sign In',
    pathname: '/sign-in',
    key: 'signIn',
    component: 'pages/auth-pages/Login',
  },
};

export const roleDefPaths = {
  Authenticated: '/dashboard',
};

export default routes;
