import { IconWrapper } from '../../components/atoms';

const ReminderSvg = () => (
  <svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.61728 1.07769C4.31409 1.25202 1.44004 3.17431 1.31976 3.2832C1.13723 3.44844 1.03134 3.70691 1.03134 3.98727C1.03134 4.30372 1.07559 4.42475 1.27387 4.65059C1.56478 4.98191 1.99148 5.08344 2.37009 4.9115C2.47476 4.86397 3.29081 4.3378 4.18359 3.74225C5.63039 2.77714 5.82065 2.63577 5.93465 2.44119C6.16012 2.05644 6.08268 1.52 5.75793 1.21709C5.47467 0.952859 4.94657 0.888312 4.61728 1.07769ZM12.516 1.04342C11.9979 1.26809 11.7769 1.92509 12.0509 2.42581C12.1513 2.60928 12.3454 2.75459 13.7579 3.70358C14.6345 4.29247 15.4571 4.82487 15.586 4.8867C16.0013 5.08583 16.4203 4.99911 16.7263 4.65059C16.9246 4.42475 16.9688 4.30372 16.9688 3.98727C16.9688 3.70691 16.8629 3.44844 16.6804 3.2832C16.5478 3.16311 13.5364 1.1548 13.3253 1.04562C13.1397 0.949671 12.7348 0.948593 12.516 1.04342ZM8.46103 3.0125C6.6081 3.19067 5.21709 3.67812 3.83489 4.63358C1.01315 6.58419 -0.456427 10.1195 0.136307 13.5312C0.738979 17.0003 3.33778 19.8293 6.72374 20.7024C7.59984 20.9283 8.24404 20.9973 9.23446 20.9712C10.1649 20.9467 10.7 20.8706 11.4376 20.6576C12.9959 20.2076 14.1978 19.4953 15.3517 18.338C16.0424 17.645 16.3805 17.21 16.8117 16.4592C18.806 12.9868 18.2271 8.54464 15.4087 5.69239C14.0285 4.29561 12.2625 3.39116 10.3595 3.10634C9.89292 3.03655 8.78203 2.98161 8.46103 3.0125ZM9.53334 7.15742C9.6276 7.22122 9.76242 7.35598 9.83287 7.45686L9.96103 7.64028L9.97387 9.61311L9.98676 11.5859L10.9214 12.5234C11.5186 13.1225 11.882 13.5238 11.928 13.6352C12.0237 13.8665 12.0176 14.1679 11.9127 14.3989C11.821 14.6006 11.5462 14.8694 11.359 14.9406C11.1609 15.016 10.7452 14.9919 10.5568 14.8942C10.4612 14.8446 9.87328 14.2909 9.25045 13.6637C8.32931 12.7362 8.10796 12.4885 8.06428 12.3359C8.02631 12.2034 8.01478 11.4864 8.02485 9.8907L8.03915 7.63297L8.17978 7.43337C8.39057 7.13417 8.66756 7.00442 9.05207 7.02491C9.28579 7.03733 9.40401 7.06991 9.53334 7.15742Z'
      fill='white'
    />
  </svg>
);
const ReminderIcon = props => <IconWrapper component={ReminderSvg} {...props} />;

export default ReminderIcon;
