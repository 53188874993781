import { IconWrapper } from '../../components/atoms';

const OrderSvg = () => (
  <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.96209 0C3.55695 0 3.17987 0.215703 2.9773 0.563086L0.424088 4.94676H8.22783V0H3.96209ZM0 6.11906V18.6218C0 19.3818 0.62508 20 1.39309 20H16.2069C16.9749 20 17.6 19.3818 17.6 18.6218V6.11906H0ZM11.4887 11.3769L8.42527 14.4085C8.30958 14.523 8.15793 14.5804 8.00632 14.5804C7.8547 14.5804 7.70305 14.523 7.58775 14.4085L6.15164 12.9877C5.92065 12.7591 5.92065 12.3878 6.15164 12.1588C6.38303 11.9298 6.75817 11.9298 6.98955 12.1588L8.00636 13.1655L10.6512 10.5481C10.8826 10.3191 11.2577 10.3191 11.4887 10.5481C11.72 10.777 11.72 11.1479 11.4887 11.3769ZM14.6223 0.561914C14.4198 0.215313 14.0427 0 13.6383 0H9.41243V4.94676H17.185L14.6223 0.561914Z'
      fill='white'
    />
  </svg>
);
const OrderIcon = props => <IconWrapper component={OrderSvg} {...props} />;

export default OrderIcon;
