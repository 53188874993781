import styled, { css } from 'styled-components';
import { Input } from 'antd';

const InputPassword = styled(Input.Password)`
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  height: 34px;
  padding: 0 15px;
  font-weight: 300;

  &:hover {
    border-color: #000 !important;
  }
  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: #c4c4c4 !important;
    border-bottom-width: 1px;
    outline: 0;
    padding: 5px;
    box-shadow: none !important;
  }
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
  ${props =>
    props.border_bottom &&
    css`
      border: none;
      border-bottom: 1px solid ${props.border_bottom};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default InputPassword;
