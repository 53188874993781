/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const TechnicianCountSlice = createSlice({
  name: 'TechnicianCount',
  initialState: false,
  reducers: {
    Count(state = false, { payload }) {
      return (state = payload);
    },
  },
});

export const { Count } = TechnicianCountSlice.actions;
export default TechnicianCountSlice.reducer;
