import styled, { css } from 'styled-components';
import { Breadcrumb as breadcrumnb } from 'antd';

const Breadcrumb = styled(breadcrumnb)`
  ${props =>
    props.arrow_center &&
    css`
      & li {
        display: flex;
      }
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      & span {
        display: flex;
        align-items: ${props.arrow_center};
        & .ant-breadcrumb-separator {
          margin: 0 16px;
        }
      }
    `}
`;

export default Breadcrumb;
