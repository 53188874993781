import React from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LogOutIcon from 'assets/custom-icons/LogOut';
import MenuIcon from 'assets/custom-icons/MenuIcon';
import { ContentWrapper, Title } from 'components/atoms/index';
import Header from 'components/atoms/Header/index';
import { removeUser } from 'app/slices/user';

const DashHeader = ({ Text, setCollapsed }) => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state?.menuOpen);

  const handleLogOut = () => {
    dispatch(removeUser());
    Navigate('/sign-in');
  };
  return (
    <Header
      align='center'
      justify='space-between'
      box_shadow='0px 4px 10px rgba(0, 0, 0, 0.15)'
      height='80px'
    >
      <ContentWrapper padding='0' align='center'>
        <MenuIcon
          onClick={() =>
            dispatch(setCollapsed({ ...menuOpen, burger_click: !menuOpen?.burger_click }))
          }
        />
        <Title mb='0' ml='22' level={1} fw='500'>
          {Text}
        </Title>
      </ContentWrapper>
      <ContentWrapper padding='0' align='center'>
        <LogOutIcon color='#1D384B' onClick={handleLogOut} />
      </ContentWrapper>
    </Header>
  );
};

export default DashHeader;
