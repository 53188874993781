import styled, { css } from 'styled-components';
import { Form as form } from 'antd';

const Form = styled(form)`
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.fd &&
    css`
      display: flex;
      flex-direction: ${props.fd};
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.ff === 'Roboto'
      ? css`
          input {
            font-family: ${'Roboto'}, sans-serif !important;
          }
        `
      : css`
          input {
            font-family: 'Poppins', sans-serif !important;
          }
        `}
        ${props =>
    props.delate_required_mark &&
    css`
      & .ant-form-item-required::before {
        display: none !important;
      }
    `}
        ${props =>
    props.sec_type === 'Add-Reminder' &&
    css`
      width: 500px;

      & .ant-col {
        width: 100% !important;
      }
      & .ant-select-selector {
        border-radius: 10px !important;
      }
      & .ant-row {
        display: block;
        & .ant-form-item-control-input {
          margin-top: 10px;
        }
      }
      & .ant-select-selector,
      & .ant-picker {
        height: 45px !important;
        width: 495px !important;
      }
      & .ant-form-item-label h5 {
        color: #b2b7c2 !important;
        white-space: pre;
      }
      & .ant-picker {
        width: 100%;
        border-color: #f0f1f4;
        border-radius: 10px;
        height: 38px;
      }
    `}
    ${props =>
    props.type === 'settings' &&
    css`
      & .ant-form-item-label label {
        color: #b2b7c2 !important;
      }
      & .ant-form-item-label {
        padding-bottom: 0px !important;
        margin-bottom: 10px;
        & label {
          margin-bottom: 0px !important;
        }
      }
      & .ant-form-item {
        margin-bottom: 24px;
      }
      & .ant-form-item-control-input-content > input,
      & .ant-input-number {
        border: 1px solid #f0f1f4;
        border-radius: 10px;
        background: white;
        width: 100%;
        height: 42px;
        padding: 5px 20px;
      }

      & .ant-input-number-group-wrapper {
        border: 1px solid #f0f1f4;
        border-radius: 10px;
        background: white;
        width: 100%;
        height: 42px;
        padding: 0px;
        & .ant-input-number-wrapper {
          height: 100%;
        }
        & .ant-input-number {
          border: none;
          border-radius: none;
          background: white;
          width: 100%;
          height: 100%;
        }
        & .ant-input-number-group-addon {
          padding: 0;
          height: 100%;
          background-color: transparent;
          border: none;
        }
      }

      & input {
        width: 100%;
      }
      & .last-input {
        margin-bottom: 120px;
        & .ant-row {
          margin-bottom: 0px !important;
        }
      }
      & .label {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #b2b7c2 !important;
      }
    `}
   ${props =>
    props.label_margin &&
    css`
      .ant-form-item-label {
        margin-bottom: ${props.label_margin} !important;
      }
    `}
   ${props =>
    props.error_nowrap &&
    css`
      .ant-form-item-explain-error {
        white-space: nowrap !important;
      }
    `}
`;

export default Form;
