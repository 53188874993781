import styled, { css } from 'styled-components';

const AfterContainer = styled.div`
  position: relative;
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
  &::after {
    ${props =>
      props.Content &&
      `
          content: "${props.Content}";
          position: absolute;
        `}
    ${props =>
      props.UrlContent &&
      `
              content: url(${props.UrlContent});
              position: absolute;
            `}
    ${props =>
      props.left &&
      `
          left: ${props.left};
        `}
        ${props =>
      props.right &&
      `
          right: ${props.right};
        `}
        ${props =>
      props.top &&
      `
          top: ${props.top};
        `}
        ${props =>
      props.bottom &&
      `
          bottom: ${props.bottom};
        `}
        ${props =>
      props.type === 'Avatar' &&
      `
              display:flex;
              align-items: center;
            `}
        ${props =>
      props.type === 'MenuCount' &&
      `
          padding: 5px 13px;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          color: white;
          height: 25px;
          background: #F2994A;
          box-shadow: 0px 6px 20px rgba(9, 189, 60, 0.42);
          border-radius: 30px;
        `}
        ${props =>
      props.type === 'OrderCustomer' &&
      `
              content: '';
              position: absolute;
              bottom:-1px;
              left:-1px;
              display: flex;
              align-items: center;
              height: 46px;
              width:46px;
              background: #F2C94C;
              border-radius: 50%;
              z-index: 0;
         `}
         ${props =>
      props.size &&
      css`
        height: 25px;
        width: 25px;
        z-index: -1;
      `}
  }
`;

export default AfterContainer;
