import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Layout = styled(layout)`
  min-height: 100vh;
  ${props =>
    props.grid_rows &&
    css`
      display: grid;
      grid-template-rows: ${props.grid_rows};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.back_color &&
    css`
      background: ${props.back_color};
    `}
    ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
`;

export default Layout;
