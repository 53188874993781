import styled, { css } from 'styled-components';
import { Menu as menu } from 'antd';

const MenuItem = styled(menu.Item)`
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
    ${props =>
    props.type === 'firstSide' &&
    css`
      height: auto !important;
      margin: 0 !important;
      width: 100%;
    `}
    ${props =>
    props.type === 'SideChild' &&
    css`
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      text-align: left;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px !important;
      & .ant-menu-title-content span {
        color: white;
      }
      & .ant-menu-title-content {
        margin-left: 0 !important;
      }
      & .ant-menu-title-content span:first-child {
        margin-left: 16px;
      }
      & span svg path {
        transition: all 0.3s !important;
      }
      &:hover {
        & span {
          color: #09bd3c;
        }
        path {
          fill: #09bd3c;
        }
      }
      & {
        path {
          fill: white;
        }
      }
      & .ant-menu-title-content {
        display: flex !important;
        align-items: center;
      }
      & .ant-menu-title-content span:nth-child(2) {
        padding: 5px 13px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        height: 25px;
        background: #f2994a;
        box-shadow: 0px 6px 20px rgba(9, 189, 60, 0.42);
        border-radius: 30px;
        margin-left: auto;
        color: white !important;
      }
    `}
`;

export default MenuItem;
