import { IconWrapper } from '../../components/atoms';

const MenuSvg = () => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.68409 4H16.9992C17.7111 4 18.3938 3.78929 18.8972 3.41421C19.4005 3.03914 19.6833 2.53043 19.6833 2C19.6833 1.46957 19.4005 0.960859 18.8972 0.585786C18.3938 0.210714 17.7111 7.90407e-09 16.9992 0H2.68409C1.97223 7.90407e-09 1.28952 0.210714 0.786152 0.585786C0.282787 0.960859 0 1.46957 0 2C0 2.53043 0.282787 3.03914 0.786152 3.41421C1.28952 3.78929 1.97223 4 2.68409 4Z'
      fill='#B2B7C2'
    />
    <path
      d='M16.9992 6.93335H2.68409C1.97223 6.93335 1.28952 7.14406 0.786152 7.51914C0.282787 7.89421 0 8.40292 0 8.93335C0 9.46378 0.282787 9.97249 0.786152 10.3476C1.28952 10.7226 1.97223 10.9333 2.68409 10.9333H16.9992C17.7111 10.9333 18.3938 10.7226 18.8972 10.3476C19.4005 9.97249 19.6833 9.46378 19.6833 8.93335C19.6833 8.40292 19.4005 7.89421 18.8972 7.51914C18.3938 7.14406 17.7111 6.93335 16.9992 6.93335Z'
      fill='#B2B7C2'
    />
    <path
      d='M16.9992 13.8667H2.68409C1.97223 13.8667 1.28952 14.0774 0.786152 14.4525C0.282787 14.8276 0 15.3363 0 15.8667C0 16.3971 0.282787 16.9058 0.786152 17.2809C1.28952 17.656 1.97223 17.8667 2.68409 17.8667H16.9992C17.7111 17.8667 18.3938 17.656 18.8972 17.2809C19.4005 16.9058 19.6833 16.3971 19.6833 15.8667C19.6833 15.3363 19.4005 14.8276 18.8972 14.4525C18.3938 14.0774 17.7111 13.8667 16.9992 13.8667Z'
      fill='#B2B7C2'
    />
  </svg>
);
const MenuIcon = props => <IconWrapper component={MenuSvg} {...props} />;

export default MenuIcon;
