import styled, { css } from 'styled-components';
import { Image as image } from 'antd';

const Image = styled(image)`
  ${props =>
    props.type === 'coomment' &&
    css`
      width: 123px;
      height: 80px;
      border-radius: 10px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

export default Image;
