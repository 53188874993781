/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const menuOpenSlice = createSlice({
  name: 'menuOpen',
  initialState: { burger_click: false, flag: true },
  reducers: {
    collapsedChange(state, { payload }) {
      return (state = payload);
    },
  },
});

export const { collapsedChange } = menuOpenSlice.actions;
export default menuOpenSlice.reducer;
