import styled, { css } from 'styled-components';
import { Table as table } from 'antd';

const Table = styled(table)`
  //* {
  //  border-color: #969696 !important;
  //}
  .expanded-table-last {
    z-index: 2;
  }

  .ant-table-cell {
    text-align: center !important;
  }

  .ant-table-column-sorters {
    display: inline-flex !important;
    flex: inherit;
  }
  .full-expanded > td {
    z-index: 1;
  }
  .ant-table {
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;

    .docs_table_header {
      display: flex;
      justify-content: space-between;
    }

    .documents_status {
      .anticon {
        margin-right: 10px;

        &.anticon-check-circle {
          color: #00dace;
        }

        &.anticon-exclamation-circle {
          color: #fc5e7a;
        }

        &.anticon-close-circle {
          color: #fc5e7a;
        }

        &.anticon-clock-circle {
          color: #35a5e4;
        }
      }
    }

    .ant-table-cell {
      padding: 5px;
    }
  }

  .ant-table-title {
    border-bottom: 1px solid #e8eef4;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 7px 10px;
    }

    > tr > th {
      background-color: #fff;
      font-weight: 500;
      font-size: 16px;
      color: #171b1e;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      &:nth-child(odd) {
        background-color: #fafafb;
      }
    }

    .ant-table-cell {
      padding: 5px 10px;
    }

    > tr {
      td {
        font-size: 14px;
        color: #717579;
        font-weight: 350;
      }

      //&:last-child {
      //  > td {
      //    border-bottom: 0;
      //  }
      //}
    }
    > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: unset;
    }
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.colswidth &&
    css`
      th {
        width: ${props.colswidth};
      }
    `}
  ${props =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${props =>
    props.row &&
    css`
      .ant-table-cell {
        width: ${props.row};
      }
    `}
  ${props =>
    props.headcolor &&
    css`
      .ant-table-thead > tr > th {
        color: ${props.headcolor};
      }
    `}
  ${props =>
    props.headerbackcolor &&
    css`
      .ant-table-thead > tr > th {
        background-color: ${props.headerbackcolor};
      }
    `}
    ${props =>
    props.header_back_color_order &&
    css`
      .ant-table {
        background-color: ${props.header_back_color_order};
      }
      .ant-table-thead > tr > th {
        background-color: ${props.header_back_color_order};
      }
    `}
  ${props =>
    props.footerbackcolor &&
    css`
      .ant-table-footer {
        background-color: ${props.footerbackcolor};
      }
    `}
  ${props =>
    props.expand_back &&
    css`
      .ant-table-expanded-row .ant-table-cell {
        background-color: ${props.expand_back};
      }
    `}
  ${props =>
    props.footertopborder &&
    css`
      .ant-table-footer {
        border-top: ${props.footertopborder} !important;
      }
    `}
  ${props =>
    props.footerpadding &&
    css`
      .ant-table-footer {
        padding: ${props.footerpadding} !important;
      }
    `}
  ${props =>
    props.cell_padding &&
    css`
      .ant-table-cell {
        padding: ${props.cell_padding} !important;
      }
    `}
  ${props =>
    props.bodycolor &&
    css`
      .ant-table-tbody > tr td {
        color: ${props.bodycolor};
      }
    `}
  ${props =>
    props.bodyweight &&
    css`
      .ant-table-tbody > tr td {
        font-weight: ${props.bodyweight};
      }
    `}
  ${props =>
    props.hide === 'body' &&
    css`
      .ant-table-tbody {
        display: none;
      }

      .ant-table,
      .ant-table-container {
        border-bottom: none;
      }

      .ant-table-thead > tr > th {
        border-bottom: none;
      }
    `}
  ${props =>
    props.extra === 'pagination' &&
    css`
      .ant-pagination-total-text {
        margin-right: auto;
      }

      .ant-pagination-item {
        background-color: #f1eaff !important;
        border-radius: 50%;
        border: 1px solid #886cc0 !important;

        a {
          color: #886cc0;
        }

        &:hover,
        &.ant-pagination-item-active {
          background-color: #886cc0 !important;

          a {
            color: #fff;
          }
        }
      }

      .ant-pagination-item-ellipsis,
      .ant-pagination-item-link-icon {
        color: #886cc0 !important;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        button {
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid #886cc0;
          color: #886cc0;
        }

        &.ant-pagination-disabled,
        &.ant-pagination-disabled:hover {
          button {
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            background-color: transparent;
          }
        }

        &:hover {
          button {
            background-color: #886cc0;
            border-radius: 50%;
            border-color: #886cc0;
            color: #fff;
          }
        }
      }
    `}
  ${props =>
    props.pagination_margin &&
    css`
      .ant-pagination {
        margin: ${props.pagination_margin};
      }
    `}
  ${props =>
    props.pagination_padding &&
    css`
      .ant-pagination {
        padding: ${props.pagination_padding};
      }
    `}
  ${props =>
    props.rowHeight &&
    css`
      .ant-table-thead > tr {
        height: ${props.rowHeight}px;
      }
    `}
  ${props =>
    props.flex === 'contents' &&
    css`
      .ant-table-thead > tr {
        display: contents;
      }
    `}
  ${props =>
    props.odd_row_color &&
    css`
      .ant-table-tbody .ant-table-row {
        &:nth-child(odd) {
          background-color: ${props.odd_row_color};
        }
      }
    `}

  ${props =>
    props.double_exp_row_color &&
    css`
      .ant-table-tbody {
        .ant-table-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            background: #fff;
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            background: ${props.double_exp_row_color};
          }
        }
        .ant-table-expanded-row {
          &:nth-child(4n),
          &:nth-child(4n-1) {
            td {
              background: ${props.double_exp_row_color};
            }
          }

          &:nth-child(4n-2),
          &:nth-child(4n-3) {
            td {
              background: #fff;
            }
          }
        }
      }
    `}
  ${props =>
    props.flex === 'contents-none' &&
    css`
      .ant-table-thead > tr > .ant-table-cell {
        padding: 11px 16px;
      }
    `}
  ${props =>
    props.expand_pos &&
    css`
      .ant-table-row-expand-icon {
        float: ${props.expand_pos};
      }
    `}
  ${props =>
    props.append_cell_back &&
    css`
      .ant-table-row-level-0 > .ant-table-cell-with-append {
        background-color: ${props.append_cell_back};
      }
    `}
  ${props =>
    props.expanded_row_extras_display &&
    css`
      .ant-table-row-level-1 > .ant-table-cell-with-append {
        .ant-table-row-expand-icon,
        .ant-table-row-indent {
          display: ${props.expanded_row_extras_display};
        }
      }
    `}
  ${props =>
    props.head_fw &&
    css`
      .ant-table-thead > tr > th {
        font-weight: ${props.head_fw};
      }
    `}
  ${props =>
    props.x_overflow &&
    css`
      .ant-table-body {
        overflow-x: ${props.x_overflow};
      }
    `}
  ${props =>
    props.y_overflow &&
    css`
      .ant-table-body {
        overflow-y: ${props.y_overflow}!important;
      }
    `}
  ${props =>
    props.height &&
    css`
      .ant-table-body {
        height: ${props.height}px;
      }
    `}
    
    ${props =>
    props.sec_type === 'customer_table' &&
    css`
      & .ant-table-column-sorters {
        margin: 0 auto !important;
      }
    `}
    /* ${props =>
    props.sec_type === 'order-align' &&
    css`
      & .ant-table-tbody tr .ant-table-cell:first-child {
        padding-left: 18px !important;
        padding-right: 18px !important;
      }
      & .ant-table-tbody tr .ant-table-cell:first-child,
      & .ant-table-tbody tr .ant-table-cell:nth-child(4),
      & .ant-table-tbody tr .ant-table-cell:nth-child(5),
      & .ant-table-tbody tr .ant-table-cell:nth-child(6) {
        & .ant-layout-content {
          justify-content: start !important;
        }
        text-align: start !important;
      }
    `} */
    ${props =>
    props.sec_type === 'services_table' &&
    css`
      & tbody .ant-typography {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        margin-bottom: 0px !important;
      }
    `}
    ${props =>
    props.type === 'order_table' &&
    css`
      & .ant-table-column-has-sorters {
        background: transparent !important;
      }
      & .ant-table-column-sorters {
        & .ant-table-column-title {
          white-space: pre;
          padding-right: 5px;
        }
        & .anticon svg {
          fill: #b2b7c2;
        }
        & .ant-table-column-sorter-down.active,
        & .ant-table-column-sorter-up.active {
          & svg {
            fill: #1890ff !important;
          }
        }
      }
      & tbody tr .ant-table-column-sort {
        background: white !important;
      }
      & .ant-table-tbody .ant-table-cell {
        padding: 0 3px !important;
      }
      & table {
        border-collapse: separate;
      }
      .ant-table-thead .ant-table-cell,
      .ant-table-thead .ant-table-column-title {
        color: #b2b7c2 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px;
        &::before {
          width: 0px !important;
        }
      }
      .ant-table-thead .ant-table-cell {
        padding: 0 0 14px 0 !important;
      }
      & .ant-table-column-sorters {
        width: fit-content;
      }
      .ant-table-tbody tr {
        background: #ffffff !important;
        height: 50px;
        margin-bottom: 2px;
      }
      .ant-table-tbody tr td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .ant-table-tbody tr td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .ant-table-tbody tr td {
        text-align: start;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #1d384b;
      }
      .ant-table-tbody tr td a {
        text-align: start;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        display: inline-block;
        height: 14px;
      }

      & .ant-pagination-item-active a {
        color: #40a9ff !important;
      }
    `}
`;

export default Table;
