import styled, { css } from 'styled-components';
import { Select as select } from 'antd';

const Select = styled(select)`
  width: 100%;
  .ant-select-selector {
    background: #fff !important;
    border: 1px solid #eeeeee !important;
    box-sizing: border-box;
    height: 35px;
    font-weight: 300;
    padding: 12px 15px !important;
    display: flex;
    align-items: center;
  }

  &:hover {
    border-color: #886cc0;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #886cc0;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #00000096;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  ${props =>
    props.type === 'order-Details-Select' &&
    css`
      & .ant-select-selector {
        border: 1px solid #f0f1f4 !important;
        border-radius: 10px !important;
        box-shadow: none !important;
        & .ant-select-selection-item {
          color: #171b1e;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }
      & .ant-select-arrow {
        display: flex;
        align-items: center;
        right: 18px !important;
        & span {
          display: flex;
        }
      }
    `}
  ${props =>
    props.type === 'blue' &&
    css`
      .ant-select-selector {
        border: 1px solid #000080;
      }
      .ant-select-arrow {
        color: #000080;
      }
    `}
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selector {
        padding: 0 5px;
      }
    `}
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      .ant-select-selector {
        padding: ${props.padding};
      }
    `}
  ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.min_width &&
    css`
      min-width: ${props.min_width};
    `}
  ${props =>
    props.height &&
    css`
      .ant-select-selector {
        height: ${props.height};
      }
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fw &&
    css`
      .ant-select-selection-item {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.back_color &&
    css`
      .ant-select-selector {
        background-color: ${props.back_color};
      }
    `}
`;

export default Select;
